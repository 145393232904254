var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-7",staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"estate","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t("MENU.home")))])])]}}])}),(_vm.role == 1)?_c('router-link',{attrs:{"to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"users-alt","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.users')))])])]}}],null,false,356800011)}):_vm._e(),(_vm.role == 1)?_c('router-link',{attrs:{"to":"/admin/admin-actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"comment-redo","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.second_opinion')))])])]}}],null,false,36612098)}):_vm._e(),(_vm.role == 1)?_c('router-link',{attrs:{"to":"/admin/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"pound-circle","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.payments')))])])]}}],null,false,2749539145)}):_vm._e(),(_vm.role == 1)?_c('router-link',{attrs:{"to":"/admin/app-settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"setting","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.general_settings')))])])]}}],null,false,448138885)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/appointment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"calender","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.appointment')))])])]}}],null,false,3285128774)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/referral"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"comment-redo","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.second_opinion')))])])]}}],null,false,36612098)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/schedule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"clock","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.schedule')))])])]}}],null,false,1972377778)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"comment-alt-question","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.questions')))])])]}}],null,false,1235735302)}):_vm._e(),(_vm.role == 3)?_c('router-link',{attrs:{"to":"/doctor/income"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"arrow-growth","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t('MENU.income')))])])]}}],null,false,1061414969)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":'/medical_file/' + _vm.$root.mf},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"disabled":!_vm.$root.mf,"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[(_vm.$root.mf)?_c('unicon',{attrs:{"name":"file-medical-alt","fill":isActive ? '#0971CE' : '#FFFFFF'}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t("MENU.medical_file")))])])]}}],null,false,46011913)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":"/user/appointment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"calender","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t("MENU.appointment")))])])]}}],null,false,4033301478)}):_vm._e(),(_vm.role == 4)?_c('router-link',{attrs:{"to":"/user/questions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"comment-alt-question","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v(_vm._s(_vm.$t("MENU.questions")))])])]}}],null,false,2454616806)}):_vm._e(),(_vm.role != 4)?_c('router-link',{attrs:{"to":"/chat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"border-radius":"5px"},attrs:{"elevation":"0","href":href,"color":isActive ? 'white' : 'primary',"fab":"","height":"45px","width":"45px","tile":""},on:{"click":navigate}},'v-btn',attrs,false),on),[_c('unicon',{attrs:{"name":"comments-alt","fill":isActive ? '#0971CE' : '#FFFFFF'}})],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971CE"}},[_vm._v("Messages")])])]}}],null,false,3031057426)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }