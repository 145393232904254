<template>
  <div style="display:flex; flex-direction:column; align-items: center;" class="pt-7">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive }">
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="estate"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t("MENU.home") }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->

    <!-- Start Admin Routes-->
    <!--begin::admin users Item-->
    <router-link
      v-if="role == 1"
      to="/admin/users"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="users-alt"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.users') }}</span>
      </v-tooltip>
     
    </router-link>
    <!--end::Item-->

    <!--begin:admin admin-actions:Item-->
    <router-link
      v-if="role == 1"
      to="/admin/admin-actions"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="comment-redo"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.second_opinion') }}</span>
      </v-tooltip>

    </router-link>
    <!--end::Item-->
<!--begin: admin app settings:Item-->
    <router-link
      v-if="role == 1"
      to="/admin/payments"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="pound-circle"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.payments') }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!--begin: admin app settings:Item-->
    <router-link
      v-if="role == 1"
      to="/admin/app-settings"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="setting"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.general_settings') }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!-- End Admin Routes-->

    <!--begin::Item-->
    <router-link
      v-if="role == 3"
      to="/doctor/appointment"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="calender"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.appointment') }}</span>
      </v-tooltip>

    </router-link>
    <!--end::Item-->

    <!--begin::Item-->
    <router-link
      v-if="role == 3"
      to="/doctor/referral"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="comment-redo"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.second_opinion') }}</span>
      </v-tooltip>

    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 3"
      to="/doctor/schedule"
      v-slot="{ href, navigate, isActive }"
    >
     <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="clock"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.schedule') }}</span>
      </v-tooltip>

     
    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 3"
      to="/doctor/question"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="comment-alt-question"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.questions') }}</span>
      </v-tooltip>

    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 3"
      to="/doctor/income"
      v-slot="{ href, navigate, isActive }"
    >
    <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="arrow-growth"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t('MENU.income')}}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 4"
      :to="'/medical_file/' + $root.mf"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!$root.mf"
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              v-if="$root.mf"
              name="file-medical-alt"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t("MENU.medical_file") }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 4"
      to="/user/appointment"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="calender"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t("MENU.appointment") }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role == 4"
      to="/user/questions"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="comment-alt-question"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">{{ $t("MENU.questions") }}</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    <!--begin::Item-->
    <router-link
      v-if="role != 4"
      to="/chat"
      v-slot="{ href, navigate, isActive }"
    >
      <v-tooltip
        :left="$root.lang == 'ar'"
        :right="$root.lang != 'ar'"
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            @click="navigate"
            :href="href"
            :color="isActive ? 'white' : 'primary'"
            fab
            height="45px"
            width="45px"
            style="border-radius:5px" class="mb-5"
            v-bind="attrs"
            v-on="on"
            tile
          >
            <unicon
              name="comments-alt"
              :fill="isActive ? '#0971CE' : '#FFFFFF'"
            ></unicon>
          </v-btn>
        </template>
        <span style="color:#0971CE">Messages</span>
      </v-tooltip>
    </router-link>
    <!--end::Item-->
    
  </div>
</template>


<script>

export default {
  name: "Aside",
  data() {
    return {
      role: 0,

    };
  },
  components: {},
  mounted() {
    this.role = JSON.parse(localStorage.userInfo).role;

    this.$nextTick(() => {
      // Init Aside
    });
    //console.log("going to load medical file id now");
    //this.loadMedicalID();
  },
  methods: {
    
  },
};
</script>
